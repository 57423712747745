// Fontawesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faArrowRightOverDot,
  faCIO,
  faCustomLanguage,
  faCalculatorPlate,
  faController,
  faDisinfection,
  faVirtualDevice,
  faGraphCompare,
  faNumeric,
  faPump,
  faSensor,
  barWidgetIndicator,
  dragNDrop,
  faLevelSensor,
} from "./customIcons";
import {
  faPhone,
  faEllipsis,
  faChevronDown,
  faChevronLeft,
  faChevronUp,
  faCircleChevronDown,
  faTriangleExclamation,
  faArrowDownShortWide,
  faArrowDownWideShort,
  faArrowDownLong,
  faArrowUpLong,
  faCircle,
  faCirclePlus,
  faMagnifyingGlassChart,
  faXmark,
  faCircleXmark,
  faFilter,
  faGear,
  faMapLocationDot,
  faLocationDot,
  faFileLines,
  faSort,
  faSortDown,
  faSortUp,
  faArrowRotateLeft,
  faArrowRotateRight,
  faMagnifyingGlass,
  faMagnifyingGlassMinus,
  faMagnifyingGlassPlus,
  faArrowsUpDownLeftRight,
  faArrowRight,
  faArrowLeft,
  faPencil,
  faFloppyDisk,
  faPlus,
  faSpinner,
  faTrash,
  faMinus,
  faGears,
  faSliders,
  faClipboardCheck,
  faVial,
  faGlassWaterDroplet,
  faCircleExclamation,
  faCheck,
  faDeleteLeft,
  faCircleInfo,
  faDroplet,
  faWarehouse,
  faBullhorn,
  faUser,
  faRightFromBracket,
  faEnvelopeOpen,
  faEnvelope,
  faHardDrive,
  faClipboard,
  faArrowDown,
  faGauge,
  faChartLine,
  faHashtag,
  faBell,
  faPaperPlane,
  faHouse,
  faKey,
  faUserPlus,
  faBars,
  faArrowUpRightFromSquare,
  faBookmark,
  faTag,
  faWrench,
  faShoePrints,
  faForwardFast,
  faAngleRight,
  faChartArea,
  faCalendarDays,
  faDownload,
  faTableCells,
  faFileExcel,
  faExclamationTriangle,
  faArrowsRotate,
  faCircleCheck,
  faCopy,
  faQuestion,
  faGaugeSimpleHigh,
  faWind,
  faBookAtlas,
  faPlusMinus,
  faCircleQuestion,
  faDatabase,
  faTemperatureHalf,
  faCloud,
  faListUl,
  faLocationArrow,
  faSun,
  faCloudSun,
  faCloudBolt,
  faCloudRain,
  faCloudShowersHeavy,
  faSnowflake,
  faSmog,
  faPaperclip,
  faEye,
  faCommentDots,
  faChevronRight,
  faClockRotateLeft,
  faChartPie,
  faWaveSquare,
  faMicrochip,
  faCalculator,
  faFlagCheckered,
  faDesktop,
  faBookOpenReader,
  faInfo,
  faReceipt,
  faSuperscript,
} from "@fortawesome/free-solid-svg-icons";

export const fontBundle = {
  faPhone,
  faEllipsis,
  faChevronDown,
  faSort,
  faSortDown,
  faSortUp,
  faTriangleExclamation,
  faCircleXmark,
  faChevronLeft,
  faChevronUp,
  faCircleChevronDown,
  faArrowDownLong,
  faArrowUpLong,
  faArrowDownShortWide,
  faArrowDownWideShort,
  faCircle,
  faCirclePlus,
  faMagnifyingGlassChart,
  faXmark,
  faFilter,
  faGear,
  faMapLocationDot,
  faLocationDot,
  faFileLines,
  faArrowRotateLeft,
  faArrowRotateRight,
  faMagnifyingGlassMinus,
  faMagnifyingGlassPlus,
  faArrowsUpDownLeftRight,
  faMagnifyingGlass,
  faArrowRight,
  faArrowLeft,
  faPencil,
  faFloppyDisk,
  faPlus,
  faSpinner,
  faTrash,
  faMinus,
  faGears,
  faSliders,
  faClipboardCheck,
  faVial,
  faGlassWaterDroplet,
  faCircleExclamation,
  faCheck,
  faDeleteLeft,
  faCircleInfo,
  faDroplet,
  faWarehouse,
  faBullhorn,
  faUser,
  faRightFromBracket,
  faEnvelopeOpen,
  faEnvelope,
  faHardDrive,
  faClipboard,
  faArrowDown,
  faGauge,
  faChartLine,
  faHashtag,
  faBell,
  faPaperPlane,
  faHouse,
  faKey,
  faUserPlus,
  faBars,
  faArrowUpRightFromSquare,
  faBookmark,
  faTag,
  faWrench,
  faShoePrints,
  faForwardFast,
  faAngleRight,
  faChartArea,
  faCalendarDays,
  faDownload,
  faTableCells,
  faFileExcel,
  faGraphCompare,
  faExclamationTriangle,
  faArrowsRotate,
  faCircleCheck,
  faCopy,
  faQuestion,
  faGaugeSimpleHigh,
  faWind,
  faBookAtlas,
  faPlusMinus,
  faArrowRightOverDot,
  faCircleQuestion,
  faCustomLanguage,
  faDatabase,
  faTemperatureHalf,
  faCloud,
  faNumeric,
  faListUl,
  faLocationArrow,
  barWidgetIndicator,
  faSun,
  faCloudSun,
  faCloudBolt,
  faCloudRain,
  faCloudShowersHeavy,
  faSnowflake,
  faSmog,
  faPaperclip,
  faEye,
  faCommentDots,
  faChevronRight,
  faClockRotateLeft,
  faChartPie,
  faWaveSquare,
  dragNDrop,
  faMicrochip,
  faCalculator,
  faCalculatorPlate,
  faVirtualDevice,
  faFlagCheckered,
  faCIO,
  faController,
  faPump,
  faDisinfection,
  faSensor,
  faLevelSensor,
  faDesktop,
  faInfo,
  faBookOpenReader,
  faReceipt,
  faSuperscript,
};

// for custom icons we need to forcefully extend the "IconName" type
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
library.add(fontBundle);

export const fontawesome = FontAwesomeIcon;
